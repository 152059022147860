/*jshint esversion: 6 */

/* GLOBAL */
let GLOBALshopsList = GLOBALshopsList ? GLOBALshopsList : null ;

let GLOBALfax = GLOBALfax ? GLOBALfax : null;
let GLOBALemail = GLOBALemail ? GLOBALemail : null;
let GLOBALcompanyAdress =GLOBALcompanyAdress ? GLOBALcompanyAdress : null;

let GLOBALrejLang = GLOBALrejLang ? GLOBALrejLang : null;
let GLOBALrejLong = GLOBALrejLong ? GLOBALrejLong : null;


$(function() {
   /* Mobile menu */
   const mobileToggleBtn = $(".mobile__toggle");
   const menu = $(".menu-menu-container");

   mobileToggleBtn.on("click", () => menu.toggleClass("open") );

    $(document).ready(function() {

        if (window.location.pathname.indexOf('/oferty-pracy') !== -1) {
            $('#praca1').lightSlider({
                gallery: true,
                item: 1,
                loop: true,
                thumbItem: 9,
                slideMargin: 0,
                enableDrag: false,
                currentPagerPosition: 'left',
                onSliderLoad: function (el) {
                    el.lightGallery({
                        selector: '#praca1 .lslide'
                    });
                }
            });

            $('#praca2').lightSlider({
                gallery: true,
                item: 1,
                loop: true,
                thumbItem: 9,
                slideMargin: 0,
                enableDrag: false,
                currentPagerPosition: 'left',
                onSliderLoad: function (el) {
                    el.lightGallery({
                        selector: '#praca2 .lslide'
                    });
                }
            });
        }

        $('#newspape').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            thumbItem: 9,
            slideMargin: 0,
            enableDrag: false,
            currentPagerPosition: 'left',
            onSliderLoad: function (el) {
                el.lightGallery({
                    selector: '#newspape .lslide'
                });
            }
        });

        lightbox.option({
            'resizeDuration': 200,
            'wrapAround': true
        })
    });

    $('.demo .lSPrev').append("<span class=\"icon\"><i class=\"fa fa-angle-left\" title=\"Previous page\"></i></span>");
    $('.demo .lSNext').append("<span class=\"icon\"><i class=\"fa fa-angle-right\" title=\"Next page\"></i></span>");

    $('.lSAction .icon').css("color", "#888");
    $('.lSAction .icon').css("font-size", "72pt");

   // $('#lightSlider').lightSlider({
   //      gallery: true,
   //      item: 1,
   //      loop:true,
   //      slideMargin: 0,
   //      thumbItem: 9
   //  });

   /* Swiper */

   const headerSlider = new Swiper('.slider__header.swiper-container', {
        autoplay: {
            delay: 5000,
        },
        speed: 700,
        spaceBetween: 0,
        parallax:true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },

    });

    const galleryThumbs = new Swiper('.swiper-thumbnails.swiper-container', {
        centeredSlides: true,
        slidesPerView: 4,
        slideToClickedSlide: true,
      });

      if($('.slider__header.swiper-container').length > 0){
        headerSlider.controller.control = galleryThumbs;
        galleryThumbs.controller.control = headerSlider;
      }



    const quotationSlider = new Swiper('.quotation__wrapper', {
        autoplay: {
            delay: 3000,
        },
        spaceBetween: 30,
        speed: 700,
        loop: true
    });

    /* Mobile menu */
    const mobileMenu = document.querySelector('.mobile__toggle__button');
    function addClassFun() {
        $(this).toggleClass("clickMenu");
    }
    $(mobileMenu).on('click', addClassFun);





    /* Loading animation */

    const gridContainer = document.querySelector('.promotion__grid');

    ScrollReveal().reveal('.grid__item', { container: gridContainer, duration: 1000, interval: 200 });



    /* Load more  */
$('.button__load-more a').on('click',function (e) {
    e.preventDefault();
})



function spinner(place){
    var innerHtml = '<div class="sk-cube-grid"><div class="sk-cube sk-cube1"></div><div class="sk-cube sk-cube2"></div><div class="sk-cube sk-cube3"></div><div class="sk-cube sk-cube4"></div><div class="sk-cube sk-cube5"></div><div class="sk-cube sk-cube6"></div><div class="sk-cube sk-cube7"></div><div class="sk-cube sk-cube8"></div><div class="sk-cube sk-cube9"></div></div>';

  place.html(innerHtml);
}





$('.promotion__grid .other-posts .grid__item').addClass('hide');


$('.see-more-offers' ).on('click', function(e){
    e.preventDefault();
    var art = $(".other-posts .grid__item").length;
    spinner($('.other-posts'));


    $.ajax({
        type:"POST",
        url : window.location.href,
        data: {
            "number" : art
        },
        success: function( data ){

            var res = $(data).find('.other-posts .grid__item');
            $('.sk-cube-grid').remove();
            $('.other-posts').prepend( res );
            $('.loader').css({"visibility" : "hidden"});
            art = $(".other-posts .grid__item").length;



        }
    });

});


/* Sierotki */
function sierotki(){
    $('section p, section span, h1, h2, h3, h4, h5, h6, p, span').each(function(){
        if($(this).length > 0) {
        var $html = $(this).html();
        $html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
            $(this).empty().html($html);
        }
    });
}
sierotki();



/* map styles */

const gMapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];



  window.markers = [];

/* Google maps shops finder class */
class ShopsFinder{

    constructor(){
        self = this,
        this.geocoder = new google.maps.Geocoder(),
        this.map = null,
        this.bounds = new google.maps.LatLngBounds(),
        this.locations = GLOBALshopsList,
        this.errorLocations = [],
        this.errorLocationCount = 0,
        this.locationCount = 0,
        this.infoWindows = [],
        this.markerIcoShop = {url:`${GLOBALhomeURL}/wp-content/uploads/2018/06/ico-mapPoint.svg`, scaledSize: new google.maps.Size(40, 40)},
        this.markerIcoOffice = {url :`${GLOBALhomeURL}/wp-content/uploads/2018/06/ico-mapPoint-active.svg`, scaledSize: new google.maps.Size(40, 40),},
        this.mapOptions = {
          center: {lat: 50.0468548, lng: 19.9348337},
          zoom: 10,
          styles: gMapStyles
          },
        this.finderForm = $('#shops__finder__form'),
        this.autocomplete = null,
        this.place = null,
        this.searchDistanceRadius = 15, // distance in km
        this.regionCoords = []
    }

    init(){
      this.initMap();
      this.setMarkers(this.locations);
      setTimeout(() => {
          this.setAutocomplete();
      }, 1000);

      this.generateRegionBorder();

      this.finderForm.on('submit', (e)=> this.formCheck(e, 'submit'));
      this.finderForm.on('change', (e)=> this.formCheck(e, 'change'));

  }

  initMap(){
    this.map = new google.maps.Map(document.getElementById('shops__finder__map-ID'), this.mapOptions);
  }


  setMarkers(locations){
        locations.map( (location, index) =>{
          this.createMarkerDOM(location);

            var marker =  new google.maps.Marker({
                    position: {lat: location.lang, lng: location.long},
                    map: this.map,
                    optimized: false,
                    icon: location.office  ? this.markerIcoOffice :  this.markerIcoShop,
                    infowindow: new google.maps.InfoWindow({
                        content:  this.infoWindows[index]
                    }),
                    customInfo: `${location.office? 'office': 'shop'}`,
                    customAdds: `${location.adds}`
                });

            window.markers.push(marker);

            marker.addListener('click', function() {
              self.closeAllMarkers();
              self.openMarker(marker);
            });
        });
    }

    setAutocomplete(){
        this.autocomplete = new google.maps.places.Autocomplete(document.querySelector('#location_input-ID'));
        this.autocomplete.bindTo('bounds', this.map);
        this.autocomplete.setComponentRestrictions(
          {'country': 'pl'});
        this.autocomplete.addListener('place_changed', ()=>{
          const place = this.autocomplete.getPlace();

          if (!place.place_id) {
            return;
          }
          this.place = place;
        });
    }



    createMarkerDOM(location){
        const openHours = location.open ? `<li><strong>Godziny otwarcia:</strong></li><li>${location.open}</li>` : '';
        const addsList = location.adds ? `<li><strong>Sklep posiada:</strong></li><li>${location.adds}</li>` : '';
          this.infoWindows.push(`
          <div class='map-info__window'>
            <div class='map-info__logo' style="background-image: url(${location.thumb})"></div>
            <ul class='map-info__address'>
                  <li>${location.street}, ${location.postCode} ${location.city}</li>
                  <li>tel./fax: ${location.tel}</li>
                  ${openHours}
                  ${addsList}

            </ul>
          </div>
          `);
    }

    generateRegionBorder(){

      //DRAW THE POLYGON OR POLYLINE
      let zone = new google.maps.Polygon({
        strokeColor: "#007031",
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: "#007031",
        fillOpacity: 0.05,
        map: self.map
    });

      $.ajax({
        dataType: "json",
        url: '../gMapJSON.json',
        success: function(data){
          data['geometries'][0]['coordinates'][0][0].map(function(val){
            self.regionCoords.push({
              lat: val[1],
              lng: val[0]
            });
          });


          zone.setPath(self.regionCoords);




        }
      });
    }

    openMarker(marker){
        marker.infowindow.open(this.map, marker);
        $('.map-info__window').parent().css('overflow-x', 'hidden');
    }

    closeAllMarkers(){
        markers.forEach(function(marker) {
          marker.infowindow.close(self.map, marker);
      });
    }

    findClosestMarker( lat, lng, markers = window.markers){

      var R = 6371; // radius of earth in km
      var distances = [];
      var closest = -1;
      for(let i=0;i<markers.length; i++ ) {
          var mlat = markers[i].position.lat();
          var mlng = markers[i].position.lng();
          var dLat  = rad(mlat - lat);
          var dLong = rad(mlng - lng);
          var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
          var d = R * c;
          distances[i] = d;

          if ( closest == -1 || d < distances[closest] ) {
              closest = i;
          }

      }

      if(distances[closest] > this.searchDistanceRadius){
        self.showMapMsg(`Nie znaleziono punktu w odległości ${this.searchDistanceRadius}km od Twojej lokalizacji.`);
      }else{
        self.closeAllMarkers();
        self.openMarker(markers[closest]);
        self.map.setZoom(15);
        self.map.panTo(new google.maps.LatLng(markers[closest].position.lat(), markers[closest].position.lng() ));
        self.showMapMsg("Punkt najbliżej Ciebie.");
      }

  }

  hideMarkers(){
    window.markers.map(marker => {
      marker.setMap(null);
    });
  }

  showMarkers(list){
    this.hideMarkers();
    list.map(marker => {
      marker.setMap(self.map);
    });
  }

  filtredMarkers(prop){
    const filtred = [];

    const shop = prop.indexOf('check_shops') != -1 ? true : false;
    const office =  prop.indexOf('check_office') != -1 ? true : false;
    const lotto =  prop.indexOf('check_lotto') != -1 ? true : false;
    const bankomat =  prop.indexOf('check_bankomat')!= -1  ? true : false;
    const inpost =  prop.indexOf('check_inpost') != -1 ? true : false;
    const swipbox =  prop.indexOf('check_swipbox') != -1 ? true : false;
    const alcobuster =  prop.indexOf('check_alcobuster') != -1 ? true : false;
    const allegro = prop.indexOf('check_allegro') != -1 ? true : false;
    const dpd = prop.indexOf('check_dpd') != -1 ? true : false;
    const dhl = prop.indexOf('check_dhl') != -1 ? true : false;
    const orlen = prop.indexOf('check_orlen') != -1 ? true : false;

    let multiSearchArr = [
      lotto ? 'Lotto' : '',
      bankomat ? 'Bankomat' : '',
      inpost ? 'InPost' : '',
      swipbox ? 'Swipbox' : '',
      alcobuster ? 'Alcobuster' : '',
      allegro ? 'Allegro' : '',
      dpd ? 'DPD' : '',
      dhl ? 'DHL' : '',
      orlen ? 'OrlenPaczka' : ''
     ];


     multiSearchArr = multiSearchArr.filter(item=>{
       if(item.length > 0){
         return item;
       }
     })

    window.markers.map((marker)=>{
      if(office && marker.customInfo =="office"){
        filtred.push(marker);
        return;
      }else if(prop.length > 1){
        var searchingIn = marker.customAdds.split(' ').join('');
        var searchingString = multiSearchArr.join(',');

        if(searchingIn.indexOf(searchingString) >= 0){
          filtred.push(marker);
          return;
        }


      }else if(lotto||bankomat||inpost||swipbox||alcobuster||allegro||dpd||dhl||orlen){
        if(lotto && marker.customAdds.indexOf("Lotto") != -1){
          filtred.push(marker);
          return false;
        }
        if(bankomat && marker.customAdds.indexOf("Bankomat") != -1){
          filtred.push(marker);
          return false;
        }
        if(inpost && marker.customAdds.indexOf("InPost") != -1){
          filtred.push(marker);
          return false;
        }
        if(swipbox && marker.customAdds.indexOf("Swipbox") != -1){
          filtred.push(marker);
          return false;
        }
        if(alcobuster && marker.customAdds.indexOf("Alcobuster") != -1){
          filtred.push(marker);
          return false;
        }
          if(allegro && marker.customAdds.indexOf("Allegro") != -1){
              filtred.push(marker);
              return false;
          }
          if(dpd && marker.customAdds.indexOf("DPD") != -1){
              filtred.push(marker);
              return false;
          }
          if(dhl && marker.customAdds.indexOf("DHL") != -1){
              filtred.push(marker);
              return false;
          }
          if(orlen && marker.customAdds.indexOf("Orlen") != -1){
              filtred.push(marker);
              return false;
          }

      }else if(shop && marker.customInfo =="shop"){
        filtred.push(marker);
      }
    })
    console.log('filtredList');
    console.log(filtred);

    return filtred;
  }

  formCheck(e, action){
    e.preventDefault();


    const chooseList = [];
    let filtredList;

    if(action === 'submit'){

      if(this.place == null && this.finderForm.find('#location_input-ID').val().length <= 0){
        self.showMapMsg("Podaj lokalizację");
        return;

      }else if(this.place == null && this.finderForm.find('#location_input-ID').val().length > 1){
        self.showMapMsg("Wybierz odpowiedni adres z podpowiedzi");
        return;
      }

      self.showMarkers(window.markers);

      self.findClosestMarker(this.place.geometry.location.lat(), this.place.geometry.location.lng());

      this.place = null;
      this.finderForm.find('#location_input-ID').val('');



    }else if (action === 'change'){


      this.finderForm.find('.checkbox').each((i, input)=>{
        if($(input).attr('id'), $(input).is(':checked')){
            chooseList.push($(input).attr('id'));
        }
      });


      filtredList = this.filtredMarkers(chooseList);


      if(chooseList.length < 1 ){
        self.showMarkers(window.markers);
      }else{
        self.showMarkers(filtredList);
      }

    }



  }

  showMapMsg(msg){
    const msgBox = $('.map-info__msgBox');
    msgBox.html(
      `<p>${msg}</p>
      <button class="hide__msgBox">OK</button>`
    );
    const height = msgBox.outerHeight();
    msgBox.css({bottom: -height});
    msgBox.find('.hide__msgBox').on('click', ()=>{
        msgBox.css({bottom: 0});
    });
  }

}

function rad(x) {return x*Math.PI/180;}


if(GLOBALshopsList != null && GLOBALshopsList.length){
    const shopsPage = new ShopsFinder();
    shopsPage.init();
}


/* Contact map */

const contactMapAddress = GLOBALcompanyAdress ? GLOBALcompanyAdress.split(' ').join("+").split(',').join('') : null;
let contactMap;


if(contactMapAddress != null){




    contactMap = new google.maps.Map(document.querySelector('.contact__map'), {
    center: {lat: +GLOBALrejLang, lng: +GLOBALrejLong},
    zoom: 16,
    styles: gMapStyles
    });

    let contentString =
    `
    <div class='map-info__window'>
    <div class='map-info__logo'></div>
    <ul class='map-info__address'>
      <li>${GLOBALcompanyAdress}</li>
      <li>tel./fax: ${GLOBALfax}</li>
      <li>email: ${GLOBALemail}</li>
    </ul>
    </div>
    `;

    const infowindow = new google.maps.InfoWindow({
    content: contentString
    });

    infowindow.setPosition({lat: +GLOBALrejLang, lng: +GLOBALrejLong})
    infowindow.open(contactMap);



}







/* Jobs */

$('.job__item__desc').slideUp();

$('.jobs__content').on('click','.job__item__name', function(e){
    $(this).closest('.job__item').find('.job__item__desc').slideToggle();
    $(this).closest('.job__item').find('.job__item__name .job-in-shops').slideToggle();
    // $(this).closest('.job__item').css('background', '#fff');
    // $(this).closest('.job__item').find('.job__item__name h3').css('color', '#007031');
    // if($(this).closest('.job__item').find('.job__item__desc').slideUp()) {
    //   $(this).closest('.job__item').css('background', '#007031;');
    //   $(this).closest('.job__item').find('.job__item__name h3').css('color', '#ffffff');
    // }
    if($(this).closest('.job__item').find('.job__item__desc').is(":visible")){
      $(this).closest('.job__item').find('.3d-flip-book, .book-widget ').css('height', '100%');
    }else{
      $(this).closest('.job__item').find('.3d-flip-book, .book-widget ').css('height', 'auto');
    }
})

$('.job__item__name .job-in-shops').map( function( i, elem){
  if( $(elem).text() !== "")  {
    $(elem).closest(".job__item__name").find(".job-in-shop").text("Ogłoszenie dotyczy wybranych sklepów");
  }
})




/* form animation */
$('.wpcf7-form input, .wpcf7-form textarea').focusin(function(){
    $(this).closest('.input__wrapper').find('label').addClass("active")
});
$('.wpcf7-form input, .wpcf7-form textarea').focusout(function(){
    if($(this).val().length <= 0 ){
        $(this).closest('.input__wrapper').find('label').removeClass("active");
    }

});

localStorage.removeItem('_grecaptcha');

    const isCaptchaChecked = () => {
        return grecaptcha.getResponse(0).length !== 0;
    }

$('.wpcf7-form').on('submit', function (e, send = false) {
    if (send === false) {
        e.preventDefault();
    }
    let sendForm = true;
    if ($(this).find('input[name="nameP"]').val() === '') {
        setTrue($(this).find('input[name="nameP"]').closest('span'));
        setError($(this).find('input[name="nameP"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="nameP"]').closest('span'));
    }
    if ($(this).find('input[name="surname"]').val() === '') {
        setTrue($(this).find('input[name="surname"]').closest('span'));
        setError($(this).find('input[name="surname"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="surname"]').closest('span'));
    }
    if ($(this).find('input[name="phone"]').val() === '') {
        setTrue($(this).find('input[name="phone"]').closest('span'));
        setError($(this).find('input[name="phone"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="phone"]').closest('span'));
    }
    if ($(this).find('input[name="email"]').val() === '') {
        setTrue($(this).find('input[name="email"]').closest('span'));
        setError($(this).find('input[name="email"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="email"]').closest('span'));
    }

    if ($(this).find('textarea[name="message"]').val() === '') {
        setTrue($(this).find('textarea[name="message"]').closest('span'));
        setError($(this).find('textarea[name="message"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('textarea[name="message"]').closest('span'));
    }

    if ($(this).find('input[name="your-name"]').val() === '') {
        setTrue($(this).find('input[name="your-name"]').closest('span'));
        setError($(this).find('input[name="your-name"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="your-name"]').closest('span'));
    }

    if ($(this).find('input[name="your-surname"]').val() === '') {
        setTrue($(this).find('input[name="your-surname"]').closest('span'));
        setError($(this).find('input[name="your-surname"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="your-surname"]').closest('span'));
    }

    if ($(this).find('input[name="your-job"]').val() === '') {
        setTrue($(this).find('input[name="your-job"]').closest('span'));
        setError($(this).find('input[name="your-job"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="your-job"]').closest('span'));
    }

    if ($(this).find('input[name="your-tel"]').val() === '') {
        setTrue($(this).find('input[name="your-tel"]').closest('span'));
        setError($(this).find('input[name="your-tel"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="your-tel"]').closest('span'));
    }

    if ($(this).find('input[name="your-email"]').val() === '') {
        setTrue($(this).find('input[name="your-email"]').closest('span'));
        setError($(this).find('input[name="your-email"]').closest('span'), 'Pole nie może być puste!');
        sendForm = false;
    } else {
        setTrue($(this).find('input[name="your-email"]').closest('span'));
    }

    console.log($(this).find('.checkbox__agree').length)
    console.log($(this).find('.checkbox__agree').length > 0 && !$(this).find('.checkbox__agree').is(':checked'))
    if ($(this).find('.checkbox__agree').length > 0 && !$(this).find('.checkbox__agree').is(':checked')) {
        setTrue($(this).find('.checkbox__agree').closest('span'));
        setError($(this).find('.checkbox__agree').closest('span'), 'Musisz wyrazić zgodę!');
        sendForm = false;
    } else {
        setTrue($(this).find('.checkbox__agree').closest('span'));
    }
    console.log(sendForm, 'cpatcha: ' + isCaptchaChecked())
    let recaptcha = $('.g-recaptcha');
    if (!isCaptchaChecked()) {
        recaptcha.find('.wpcf7-not-valid-tip').remove();
        let error = `<span class="wpcf7-not-valid-tip">ReCaptcha jest polem wymaganym!</span>`;
        sendForm = false;
        recaptcha.append(error);
    } else {
        recaptcha.find('.wpcf7-not-valid-tip').remove();
    }

    if (sendForm === true) {
        $(this.submit(e, true));
    }
});

function setError(input, message) {
    let content = input.closest('span');
    let error = `<span class="wpcf7-not-valid-tip">${message}</span>`;
    content.append(error);
}

function setTrue(input) {
    let content = input.closest('span');
    content.find('.wpcf7-not-valid-tip').remove();
}

function validEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

$('#file').on('change',function(){
    let fileName = $(this).val().split("\\").slice(-1)[0];
    $('label[for="file"]').text(fileName);
})

/* Home promotions search */

$('#search__shop').autoComplete({
  minChars: 0,
  source: function(term, suggest){
    term = term.toLowerCase();

    var choices = GLOBALshopsAddresses;
    var matches = [];
    for (i=0; i<choices.length; i++)
        if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
    suggest(matches);
  }
});



$('#search__promotions__form').on('submit', (e)=>{
  e.preventDefault();
  let searchingShop = $('#search__shop').val();
  const searchingCategory = $('#search__category').val();

  if(searchingShop.trim().length < 1){
    searchingShop = 'all';
  }

  $('.promotions__section__2').remove();
  spinner($('.promotion__grid .grid__default'));
  $('.sk-cube-grid').prepend("Szukam promocji");

  $.ajax({
    url: "./allpromotions",
    success: (data)=>{
      $('.sk-cube-grid').remove();

      $(data).map((i, val)=>{
        if( $(val).find('.shop__list__allShops').text() === "Promocja we wszystkich sklepach" && (searchingCategory === 'all' || searchingCategory === $(val).data('category')) ){
          $('.promotion__grid .grid__default').append(val);
        }else if( (searchingShop === 'all' || $(val).find('.promotion-in-shops__one-shop').text() === searchingShop) && (searchingCategory === 'all' || searchingCategory === $(val).data('category')) ){
          $('.promotion__grid .grid__default').prepend(val);
        }else if($(val).find('.promotion-in-shops__list .shop__list__item').length > 1 && (searchingCategory === 'all' || searchingCategory === $(val).data('category')) ){

          $(val).find('.promotion-in-shops__list .shop__list__item').map((i, item)=>{

            if( searchingShop === 'all' || $(item).text() === searchingShop){
              $('.promotion__grid .grid__default').prepend(val);
            }
          });
        }

      });

      if($('.promotion__grid .grid__item').length < 1){
        $('.promotion__grid .grid__default').prepend($('<div class="empty">Brak wyników</div>'));
      }

    }
  });
});


$('.site__wrapper').on('click', '.show__shops-list', (e)=>{
 $(e.currentTarget).closest('.desc').find('.promotion-in-shops__list').toggleClass('active');
 if($(e.currentTarget).find('span').text() === '(pokaż)'){
    $(e.currentTarget).find('span').text('(schowaj)');
 }else{
  $(e.currentTarget).find('span').text('(pokaż)');
 }

});


/* Jobs searching */

const offersList = $('.job__item');
$('#search__jobs__form').on('submit', (e)=>{
  e.preventDefault();
  const addressToFind = $('#search__shop').val().trim();
  offersList.remove();
  $('.empty').remove();





  if(addressToFind.length === 0){
    $('#search__jobs__form').after(offersList);
  }else{
    offersList.map((i, item)=>{

      //if($(item).find('#sklep-where').data('address').split(' ').join('') === addressToFind.split(' ').join('')){
      if($(item).find('.job-in-shops').data('address').split(' ').join('').indexOf(addressToFind.split(' ').join('')) >= 0){

        $('#search__jobs__form').after(item);
      }
    });
  }

  if($('.job__item').length === 0){
    $('#search__jobs__form').after('<div class="empty">Nie znaleziono oferty pracy w wybranym przez Ciebie sklepie</div>');
  }

});





//assortmen loadMore
if($('.wydarzenia').length > 0){

  var container = $('.wydarzenia');
  var items = $('.wydarzenia>.grid');
  var itemsNumber = $('.wydarzenia>.grid').length;
  var itemsPerLoad = 4;
  var loadedItem = 0;

  var showMoreBtn = $('.show__more__events');


  container.empty();

  for(var i = 0; i < itemsPerLoad; i++){
    container.append(items[i]);
    loadedItem++;
  }

  container.css('display', 'flex');


  showMoreBtn.on('click', function(){
    for(var i = loadedItem ; i < (loadedItem + itemsPerLoad); i++){
      container.append(items[i]);
    }
    loadedItem = loadedItem+itemsPerLoad;

    console.log(loadedItem, itemsNumber);
    if(loadedItem >= itemsNumber){
      $(this).text('Nie ma więcej wydarzeń').attr('disabled', true);
    }
  });




};



if($('.see-more-offers').length > 0){

  if(globalPromotionsLength <= 7){
    $('.see-more-offers').hide();
  }
}


$('.email__hidden').on('click', function(){
  var emailId = '#'+$(this).attr('id').split('-')[1];

  var url = 'www.zacheta.pl/stae/ukryte-emaile/';

  var $self = $(this);
  $.ajax({
    url: url,
    success: function(data){
      $self.text($(data).closest(emailId).text())
    }
  });



});



});//close ready
